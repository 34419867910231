/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'custom/index';


//css
@import url('../pages/presentation/buttonsPages/styles/addVehicleStyle.css');
@import url('../pages/presentation/buttonsPages/styles/detailVehicleStyle.css');

// modal css
@import url('../utils/modal.css');

// Alert css
@import url('../utils/alert/alert.css');

// Loading css
@import url('../utils/loading/loading.css');

// Vehicle css
@import url('../pages/presentation/single-pages/styles/vehicle.css');
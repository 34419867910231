.modalContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    /* bottom: 0; */
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999; /* 6 */
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modalContainer.show {
    opacity: 1;
    pointer-events: visible;
}

.modalContent {
    margin-top: 10px;
    border-radius: 10px;
    max-width: 500px;
    width: 93%;
    background-color: rgb(0, 0, 0);
    color: rgb(238, 238, 238);
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}

.modalContainer.show .modalContent {
    transform: translateY(0);
}

.modalHeader, .modalFooter {
    padding: 13px 0;
}
.modal-input {
    margin-bottom: 12px;
}
.modal-input p {
    margin-bottom: 6px;
}
.modal-input input {
    height: 32px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #747474;
}
.modalTitle {
    margin: 0;
    color: rgb(238, 238, 238);
}

.modalBody {
    padding: 7px 0;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
}

/* Refund modal */
.refundModalContainer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999; /* 6 */
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}
.refundModalContainer.show {
    opacity: 1;
    pointer-events: visible;
}
.refundModalContent {
    margin-top: 10px;
    padding: 15px;
    border-radius: 10px;
    width: 500px;
    background-color: rgb(218, 218, 218);
    color: rgb(39, 39, 39);
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
}
.refundModalContainer.show .refundModalContent {
    transform: translateY(0);
}
.cancle-spacing {
    margin-left: 30px;
}

.error--msg {
    color: red;
}
